// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("/opt/build/repo/src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-bodenbelaege-js": () => import("/opt/build/repo/src/pages/bodenbelaege.js" /* webpackChunkName: "component---src-pages-bodenbelaege-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fassdichte-js": () => import("/opt/build/repo/src/pages/fassdichte.js" /* webpackChunkName: "component---src-pages-fassdichte-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kundenservice-js": () => import("/opt/build/repo/src/pages/kundenservice.js" /* webpackChunkName: "component---src-pages-kundenservice-js" */),
  "component---src-pages-moebelmanufaktur-js": () => import("/opt/build/repo/src/pages/moebelmanufaktur.js" /* webpackChunkName: "component---src-pages-moebelmanufaktur-js" */),
  "component---src-pages-polstern-js": () => import("/opt/build/repo/src/pages/polstern.js" /* webpackChunkName: "component---src-pages-polstern-js" */),
  "component---src-pages-restauration-js": () => import("/opt/build/repo/src/pages/restauration.js" /* webpackChunkName: "component---src-pages-restauration-js" */),
  "component---src-pages-sicht-sonnenschutz-js": () => import("/opt/build/repo/src/pages/sicht-sonnenschutz.js" /* webpackChunkName: "component---src-pages-sicht-sonnenschutz-js" */),
  "component---src-pages-ueber-uns-js": () => import("/opt/build/repo/src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-wandgestaltung-js": () => import("/opt/build/repo/src/pages/wandgestaltung.js" /* webpackChunkName: "component---src-pages-wandgestaltung-js" */)
}

